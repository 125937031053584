import React, { useState } from "react";
import { theme } from "../styles/theme";
import { css } from "emotion";
import { BLOCKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const renderOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const { title, file } = node.data.target.fields;
      return <img src={file.url} alt={title} />;
    },
  },
};

const faqStyle = css({
  marginBottom: theme.gap,
  borderRadius: theme.borderRadius,
  border: `2px solid ${theme.colours.purple}`,
  boxShadow: theme.shadow,

  header: {
    button: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      textAlign: "left",
      background: theme.colours.purple,
      color: theme.colours.white,
      padding: theme.gap,
      fontSize: theme.fzH2,
      border: "none",
      appearance: "none",
      width: "100%",
      outlineColor: theme.colours.purpleTint,

      svg: {
        flexShrink: 0,
        marginLeft: theme.gap,
      },
    },
  },
});

const faqContent = css({
  padding: theme.gap,

  "div.theme": {
    padding: theme.gapHalf,
    border: `1px solid ${theme.colours.lightGrey}`,
    borderRadius: theme.borderRadius,
    marginBottom: theme.gapHalf,
  },
});

const FaqItem = ({ question, answer, id }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <article className={faqStyle}>
      <header>
        <button aria-controls={id} aria-expanded={open} onClick={handleClick}>
          <h2>{question}</h2>
          <svg
            height="24"
            width="24"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 0h24v24H0z" stroke="none" />
            {!open && <line x1="12" x2="12" y1="5" y2="19" />}
            <line x1="5" x2="19" y1="12" y2="12" />
          </svg>
        </button>
      </header>
      {open && (
        <div className={faqContent} id={id}>
          {answer && (
            <div className="theme">
              {documentToReactComponents(answer, renderOptions)}
            </div>
          )}
        </div>
      )}
    </article>
  );
};

export default FaqItem;
