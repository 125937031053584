import React from "react";
import {
  Document,
  Page,
  Image,
  Text,
  View,
  Link,
  StyleSheet,
} from "@react-pdf/renderer";
import { theme } from "../styles/theme";
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFF",
    paddingTop: 20,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontFamily: "Helvetica",
  },
  image: {
    width: "52mm",
    height: "40mm",
  },
  section: {
    marginBottom: 10,
    paddingVertical: 10,
    flexGrow: 1,
  },
  header: {
    paddingHorizontal: 10,
    paddingVertical: 14,
    backgroundColor: theme.colours.purple,
    color: theme.colours.white,
    fontSize: "12pt",
    fontFamily: "Helvetica-Bold",
    fontWeight: "bold",
    marginBottom: 6,
  },
  textsection: {
    padding: 10,
    fontSize: "12pt",
    color: theme.colours.midGrey,
    borderColor: theme.colours.lightGrey,
    borderStyle: "solid",
    borderWidth: "0.5mm",
    marginHorizontal: 6,
    marginBottom: 6,
  },
  subtopic: {
    borderColor: theme.colours.purple,
    borderStyle: "solid",
    borderWidth: "0.5mm",
    marginBottom: 12,
  },
  marginBottom: {
    marginBottom: 5,
  },
  ul: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 5,
  },
  ol: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 5,
  },
  li: {
    display: "flex",
    paddingLeft: 5,
  },
  heading: {
    marginBottom: 5,
    fontWeight: "bold",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

const rtRenderOptions = {
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => (
      <Text style={styles.heading}>{children}</Text>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <Text style={styles.heading}>{children}</Text>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <Text style={styles.heading}>{children}</Text>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <Text style={styles.heading}>{children}</Text>
    ),
    [BLOCKS.HEADING_5]: (node, children) => (
      <Text style={styles.heading}>{children}</Text>
    ),
    [BLOCKS.HEADING_6]: (node, children) => (
      <Text style={styles.heading}>{children}</Text>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <Text style={styles.marginBottom}>{children}</Text>
    ),
    [BLOCKS.UL_LIST]: (node, children) => (
      <View style={styles.ul}>{children}</View>
    ),
    [BLOCKS.OL_LIST]: (node, children) => (
      <View style={styles.ol}>{children}</View>
    ),
    [BLOCKS.LIST_ITEM]: (node, children) => (
      <Text style={styles.li}>- {children}</Text>
    ),
    [BLOCKS.QUOTE]: (node, children) => <Text>{children}</Text>,
    [BLOCKS.HR]: (node, children) => <Text>{children}</Text>,
    [BLOCKS.EMBEDDED_ASSET]: (node, children) => null,
    [BLOCKS.EMBEDDED_ENTRY]: (node, children) => null,
    [INLINES.HYPERLINK]: (node, children) => (
      <Link
        style={{ color: theme.colours.purple, textDecoration: "underline" }}
        src={node.data.uri}
      >
        {children}
      </Link>
    ),
  },
  renderMark: {
    [MARKS.BOLD]: (text) => (
      <Text
        style={{
          fontFamily: "Helvetica-Bold",
          fontWeight: "bold",
        }}
      >
        {text}
      </Text>
    ),
    [MARKS.ITALIC]: (text) => (
      <Text
        style={{
          fontFamily: "Helvetica-Oblique",
          fontStyle: "oblique",
        }}
      >
        {text}
      </Text>
    ),
    [MARKS.UNDERLINE]: (text) => (
      <Text
        style={{
          textDecoration: "underline",
        }}
      >
        {text}
      </Text>
    ),
    [MARKS.CODE]: (text) => <Text>{text}</Text>,
  },
};

const contentsMapped = (content) => {
  return content.map((block, i) => (
    <View key={i}>
      {block.selectedSubtopics.map((s) => {
        return (
          <View key={s.sys.id} style={styles.subtopic} wrap={false}>
            <Text style={styles.header}>{s.fields.name}</Text>
            {s.fields.textBlockOne && (
              <View style={styles.textsection}>
                {documentToReactComponents(
                  s.fields.textBlockOne,
                  rtRenderOptions
                )}
              </View>
            )}
            {s.fields.textBlockTwo && (
              <View style={styles.textsection}>
                {documentToReactComponents(
                  s.fields.textBlockTwo,
                  rtRenderOptions
                )}
              </View>
            )}
          </View>
        );
      })}
    </View>
  ));
};

// Create Document Component
const PdfDocument = ({ title, content }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View>
        <Image src="/logo512.png" style={styles.image} />
      </View>
      <View style={styles.section}>{contentsMapped(content)}</View>
      <Text
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
        fixed
      />
    </Page>
  </Document>
);

export default PdfDocument;
