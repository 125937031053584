import React from "react";
import { theme } from "../styles/theme";
import { css } from "emotion";

const Header = ({ title }) => {
  return (
    <header className={css(theme.components.header)}>
      <h1>{title}</h1>
    </header>
  );
};

export default Header;
