import { css } from "emotion";

const colours = {
  purpleLight: "#a766b1",
  purple: "#843F8F",
  purpleTint: "#5A2961",
  greenLight: "#3CC7CD",
  green: "#00958F",
  teal: "#1E7478",
  lightGrey: "#F2F1F1",
  midGrey: "#656162",
  black: "#312C2D",
  white: "#FFFFFF",
  error: "#d60031",
};
const borderRadius = "2px";
const gapDouble = "2rem";
const gap = "1rem";
const gapHalf = "0.5rem";
const fzH1 = "1.5rem";
const fzH2 = "1.25rem";
const fzH3 = "1.125rem";
const shadow = "0 3px 6px rgba(0, 0, 0, 0.16)";
const gradientVertical = `linear-gradient(to top, ${colours.purpleTint}, #5A2961)`;

export const theme = {
  colours,
  borderRadius,
  gapDouble,
  gap,
  gapHalf,
  fzH1,
  fzH2,
  fzH3,
  shadow,
  gradientVertical,
  components: {
    row: css({
      maxWidth: "1000px",
      padding: gap,
      margin: "0 auto",
    }),
    title: css({
      color: colours.white,
      fontWeight: "bold",
    }),
    button: css({
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: `${gap} ${gapDouble}`,
      borderRadius: borderRadius,
      fontWeight: "bold",
      textTransform: "uppercase",
      textDecoration: "none",
      textAlign: "center",
      backgroundColor: colours.purple,
      color: colours.white,
      appearance: "none",
      width: "100%",
      border: "none",
      "&:hover, &:focus": {
        backgroundColor: colours.purpleTint,
      },
      "&:disabled": {
        backgroundColor: colours.midGrey,
        pointerEvents: "none",
      },
    }),
    buttonSecondary: css({
      backgroundColor: colours.lightGrey,
      color: colours.midGrey,
      "&:hover, &:focus": {
        backgroundColor: colours.lightGrey,
      },
    }),
    header: css({
      backgroundColor: colours.purple,
      background: gradientVertical,
      color: colours.white,
      textAlign: "center",
      padding: gap,
      minHeight: "125px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: fzH2,
      fontWeight: "bold",
      h1: {
        fontSize: "inherit",
        maxWidth: "240px",
      },
    }),
  },
};
