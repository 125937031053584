import React, { useState, useContext, useEffect } from "react";
import { Redirect } from "react-router-dom";

import TopicContext from "../context/TopicContext";

import Header from "../components/Header";
import Card from "../components/Card";
import Error from "../components/Error";
import FeedbackButton from "../components/FeedbackButton";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { INLINES } from "@contentful/rich-text-types";

import downloadIcon from "../assets/icon-cm-download.svg";
import trackEvent from "../utils/trackEvent";
import generatePdf from "../utils/generatePdf";
import { theme } from "../styles/theme";
import { css, cx } from "emotion";

const topicContentStyle = css({
  padding: theme.gap,

  "div.theme": {
    padding: theme.gapHalf,
    border: `1px solid ${theme.colours.lightGrey}`,
    borderRadius: theme.borderRadius,
    marginBottom: theme.gapHalf,
  },
});
const topicSubtitleStyle = css({
  color: theme.colours.purpleTint,
  fontSize: theme.fzH2,
  marginBottom: theme.gap,
});

const feedbackContainerStyle = css({
  background: theme.colours.purpleLight,
  display: "flex",
  alignItems: "center",
  padding: `${theme.gap} ${theme.gapHalf}`,
  color: theme.colours.white,
  borderRadius: theme.borderRadius,
  fontWeight: "bold",
  transition: "background-color 0.2s ease",
});

const downloadButtonStyle = css({
  background: theme.colours.purple,
  display: "flex",
  alignItems: "center",
  marginLeft: theme.gap,
  borderRadius: theme.borderRadius,
  padding: `${theme.gapHalf} ${theme.gap}`,
  color: theme.colours.white,
  fontWeight: "bold",
  transition: "box-shadow 0.2s ease",
  textTransform: "uppercase",
  fontSize: 15,
  textDecoration: "none",
  gap: theme.gapHalf,

  "&:hover, &:focus": {
    boxShadow: "0 0 0 2px #FFF",
  },
});

const submittedStyle = css({
  background: theme.colours.midGrey,
  pointerEvents: "none",
});

const rtRenderOptions = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => (
      <a
        onClick={() => {
          trackEvent("outbound", "click", node.data.uri);
        }}
        href={node.data.uri}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    ),
  },
};

const Guide = () => {
  const { guideContent, clearSelections, updateGuideContent, error } =
    useContext(TopicContext);

  const [navigateHome, setNavigateHome] = useState(false);
  const [triedSessionStorage, setTriedSessionStorage] = useState(false);
  const [feedbackGivenList, setFeedbackGivenList] = useState([]);
  const [restoredGuideContent, setRestoredGuideContent] = useState([]);

  const handleFeedback = (e, id, value, name) => {
    e.preventDefault();
    trackEvent(
      "Subtopic",
      value === "yes" ? "Feedback Useful" : "Feedback Not Useful",
      name
    );
    setFeedbackGivenList([...feedbackGivenList, { id, value }]);
  };

  const handleCompetencyDownload = (name) => {
    trackEvent("Topic", "Competencies Download", name);
  };

  const handleStartAgain = () => {
    clearSelections();
    updateGuideContent();
    setNavigateHome(true);
  };

  const isFeedbackGiven = (id, value) => {
    if (!feedbackGivenList.length) return false;

    const feedbackItem = feedbackGivenList.find((item) => item.id === id);

    // If the id isn't in the feedback given list
    if (!feedbackItem) return false;

    // if we're just checking the block (not checking value)
    if (!value) return true;
    else if (feedbackItem.value === value) return true;

    return false;
  };

  const DownloadButton = ({ link, topicTitle }) => (
    <a
      href={link}
      download
      target="_blank"
      rel="noopener noreferrer"
      className={downloadButtonStyle}
      title={`Download competency pdf for ${topicTitle}`}
      onClick={(e) => handleCompetencyDownload(topicTitle)}
    >
      <img src={downloadIcon} width="20" height="20" alt="Download" />
      <span>Competencies</span>
    </a>
  );
  const renderGuideContent = () => {
    const gc = guideContent.length ? guideContent : restoredGuideContent;
    return gc.map((topic) => {
      return topic.selectedSubtopics.map((s) => {
        return (
          <li key={s.sys.id}>
            <Card
              title={topic.fields.name}
              image={topic.fields.iconImage}
              headerRight={
                topic.fields.competencyPdf ? (
                  <DownloadButton
                    link={topic.fields.competencyPdf.fields.file.url}
                    topicTitle={topic.fields.name}
                  />
                ) : null
              }
            >
              <div className={topicContentStyle}>
                <h3 className={topicSubtitleStyle}>{s.fields.name}</h3>
                {s.fields.textBlockOne && (
                  <div className="theme">
                    {documentToReactComponents(
                      s.fields.textBlockOne,
                      rtRenderOptions
                    )}
                  </div>
                )}
                {s.fields.textBlockTwo && (
                  <div className="theme">
                    {documentToReactComponents(
                      s.fields.textBlockTwo,
                      rtRenderOptions
                    )}
                  </div>
                )}
                <div
                  className={cx(feedbackContainerStyle, {
                    [submittedStyle]: isFeedbackGiven(s.sys.id, null),
                  })}
                >
                  <p
                    className={css({
                      flexGrow: 1,
                    })}
                  >
                    Was this useful?
                  </p>
                  <FeedbackButton
                    id={s.sys.id}
                    value="yes"
                    submitted={isFeedbackGiven(s.sys.id, "yes")}
                    onChange={(e) =>
                      handleFeedback(e, s.sys.id, "yes", s.fields.name)
                    }
                  />
                  <FeedbackButton
                    id={s.sys.id}
                    value="no"
                    submitted={isFeedbackGiven(s.sys.id, "no")}
                    onChange={(e) =>
                      handleFeedback(e, s.sys.id, "no", s.fields.name)
                    }
                  />
                </div>
              </div>
            </Card>
          </li>
        );
      });
    });
  };

  useEffect(() => {
    // Restore guide content if it exists (e.g. if user returns to guide from other window)
    if (!guideContent.length && window.sessionStorage.getItem("guideContent")) {
      console.log("Restoring guide content from session storage");
      setRestoredGuideContent(
        JSON.parse(window.sessionStorage.getItem("guideContent"))
      );
    } else {
      setTriedSessionStorage(true);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {navigateHome && <Redirect to="/" />}

      {!guideContent.length &&
        !restoredGuideContent.length &&
        triedSessionStorage && <Redirect to="topics" />}

      {error && <Error error={error} />}

      <Header title="Your Checklist" />

      <main>
        <ul className="mb">{renderGuideContent()}</ul>

        <button
          className={cx(
            theme.components.button,
            css({ marginBottom: theme.gap })
          )}
          onClick={() =>
            generatePdf(
              guideContent.length ? guideContent : restoredGuideContent
            )
          }
        >
          Save Page as PDF
        </button>
        <button className={theme.components.button} onClick={handleStartAgain}>
          Start Again
        </button>
      </main>
    </>
  );
};

export default Guide;
