import React, { useState, useEffect } from "react";
import { createClient } from "contentful";
import getContentfulEnvironment from "../utils/getContentfulEnvironment";

const accessToken = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN;
const space = process.env.REACT_APP_CONTENTFUL_SPACE;

const TopicContext = React.createContext();

export const TopicProvider = ({ children }) => {
  const [topics, setTopics] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [selectedSubtopics, setSelectedSubtopics] = useState([]);
  const [guideContent, setGuideContent] = useState([]);
  const [error, setError] = useState(null);

  // Add or remove the id of the topic
  const updateSelectedTopics = (id) => {
    let newSelected = [...selectedTopics];
    if (newSelected.includes(id)) {
      setSelectedTopics(newSelected.filter((s) => s !== id));
    } else {
      setSelectedTopics([...newSelected, id]);
    }
  };

  // Add or remove the id of the subtopic
  const updateSelectedSubtopics = (id) => {
    let newSelected = [...selectedSubtopics];
    if (newSelected.includes(id)) {
      setSelectedSubtopics(newSelected.filter((s) => s !== id));
    } else {
      setSelectedSubtopics([...newSelected, id]);
    }
  };

  // Build up guide content based on selected topics and subtopics
  const updateGuideContent = () => {
    if (!selectedTopics.length || !selectedSubtopics.length) return;

    // Filter topics and add selectedSubtopics property to selected ones
    const reduced = [...topics].reduce((selectedGuideContent, topic) => {
      if (!selectedTopics.includes(topic.sys.id)) return selectedGuideContent;
      topic.selectedSubtopics = topic.fields.subtopic.filter((sub) =>
        selectedSubtopics.includes(sub.sys.id)
      );
      // console.log({ topic });
      return [...selectedGuideContent, topic];
    }, []);

    // Store in session storage to use if the user leaves the app and returns
    window.sessionStorage.setItem("guideContent", JSON.stringify(reduced));

    setGuideContent(reduced);
  };

  // Clear all selections to reset state
  const clearSelections = () => {
    setSelectedTopics([]);
    setSelectedSubtopics([]);
  };

  // Create contentful client
  const client = createClient({
    space,
    accessToken,
    environment: getContentfulEnvironment(),
  });

  // Fetch topics data from contentful and setState
  useEffect(() => {
    async function fetchData() {
      try {
        const topicsData = await client.getEntries({
          content_type: "topic",
          order: "fields.order",
        });
        setTopics(topicsData.items);
        window.localStorage.setItem(
          "contentfulTopics",
          JSON.stringify(topicsData.items)
        );
      } catch (error) {
        console.log({ error });
        const { message } = error;
        setError(message);
      }
    }

    if (navigator.onLine) {
      fetchData();
    } else if (window.localStorage.getItem("contentfulTopics")) {
      setTopics(JSON.parse(window.localStorage.getItem("contentfulTopics")));
    } else {
      setError("Please check you are connected to the internet");
    }

    // eslint-disable-next-line
  }, []);

  return (
    <TopicContext.Provider
      value={{
        topics,
        selectedTopics,
        updateSelectedTopics,
        selectedSubtopics,
        updateSelectedSubtopics,
        guideContent,
        updateGuideContent,
        clearSelections,
        error,
      }}
    >
      {children}
    </TopicContext.Provider>
  );
};

export default TopicContext;
