import React from "react";
import BackLink from "../components/BackLink";

import { theme } from "../styles/theme";
import { css } from "emotion";

const Privacy = () => {
  return (
    <>
      <header className={css(theme.components.header)}>
        <h1>COG-OT Project Privacy Notice</h1>
      </header>
      <main>
        <BackLink to="/">Back to home</BackLink>
        <article className="theme">
          <p>
            COG-OT ("We") are committed to protecting your personal data and
            respecting your privacy. This notice (together with our terms of
            use, which can be found at{" "}
            <a href="https://cog-ot.co.uk/privacy">
              https://cog-ot.co.uk/privacy
            </a>{" "}
            and any other documents referred to in them sets out the basis on
            which any personal data we collect from you, or you provide to us,
            will be processed by us. The notice also describes your rights over
            the data we collect and how you can exercise your rights.
          </p>
          <h2>
            WHAT INFORMATION DO WE COLLECT AND PROCESS ABOUT YOU WHEN YOU VISIT
            OUR WEBSITE?
          </h2>
          <p>
            We (COG-OT) do not collect any personally identifiable data when you
            use our website{" "}
            <a href="https://cog-ot.co.uk/">https://cog-ot.co.uk/</a> (“our
            site”). We will use Google Analytics to collect information about
            your visit including:
            <li>Onboarding responses</li>
            <li>Topic selections</li>
            <li>Subtopic selections</li>
            <li>Yes/No was a subtopic useful feedback</li>
            <li>General page views</li>
          </p>
          <p>
            By using our site you will also be agreeing to privacy and terms
            outlined for Google Analytics:{" "}
            <a href="https://support.google.com/analytics/answer/7318509?hl=en">
              https://support.google.com/analytics/answer/7318509?hl=en
            </a>
          </p>
          <h2>WHEN YOU CONTACT US</h2>
          We will collect your information when you correspond with us by the
          cog.ot.project@gmail.com e-mail or otherwise and will collect
          information each time you visit our sites. The information you give us
          may include your name and e-mail address. We do this based on our
          legitimate interest to ensure that we can respond to you to requests
          for information about our services.
          <h2>FOR MARKETING PURPOSES</h2>
          <p>
            We will never sell your data nor provide access to it to any third
            parties for marketing purposes. We will only use your email address
            for the purposes of the project not marketing. We do this based on
            our legitimate interest. However, you can ask us to stop sending you
            email messages at any time by email to the{" "}
            <a href="mailto:cog.ot.project@gmail.com">
              cog.ot.project@gmail.com
            </a>
            .
          </p>
          <h2>WHAT DO WE DO WITH YOUR PERSONAL DATA?</h2>
          <p>
            We will use the information for our legitimate interest to improve
            our site to ensure the content is presented in the most effective
            manner for you as part of our efforts to provide a service and to
            keep our site safe and secure. We will only use your information
            when the law allows us, in this instance, where we need to comply
            with a legal or regulatory obligation.
          </p>
          <p>
            Our website uses cookies to distinguish you from other users of our
            website. This helps us to provide you with a good experience when
            you browse our website and allows us to improve our site. A cookie
            is a small file of letters and numbers that we store on your browser
            or the hard drive of your computer if you agree. The cookies will
            collect the following information when you use our site:
          </p>
          <ul>
            <li>
              Technical information, including the Internet protocol (IP)
              address used to connect your computer to the Internet
            </li>
            <li>Your login information, browser type and version</li>
            <li>Time zone setting</li>
            <li>Browser plug-in types and versions </li>
            <li>Operating system and platform</li>
            <li>Mobile device type and version</li>
            <li>Anonymous statistics about message transmission</li>
          </ul>
          <p>Please read our cookie policy for further information.</p>
          <h2>DISCLOSURE OF YOUR INFORMATION</h2>
          <p>
            We will only share your personal data with third parties where we
            have a legal obligation to do so:
          </p>
          <ul>
            <li>
              Where we are under a duty to disclose or share your personal data
              in order to comply with any laws, or in order to enforce or apply
              our terms of use or any other terms within the agreements; or to
              protect the rights, property, or safety of COG-OT, our customers,
              or where we are required to share it with government agencies or
              regulators.
            </li>
          </ul>
          <h2>RETAINING YOUR INFORMATION</h2>
          <p>
            We will only retain your information for as long as necessary to
            fulfil the purposes for which we collected it, including for the
            purposes of satisfying any legal, accounting, regulatory, or
            reporting requirements. To determine the appropriate retention
            period for personal data, we consider the amount, nature, and
            sensitivity of the personal data, the potential risk of harm from
            unauthorised use or disclosure of your personal data, the purposes
            for which we process your personal data and whether we can achieve
            those purposes through other means, and the applicable legal
            requirements
          </p>
          <h2>WHAT ARE YOUR RIGHTS?</h2>
          <p>You have the following rights:</p>
          <ul>
            <li>to ask us not to process your personal data at any time;</li>
            <li>to access your personal data; </li>
            <li>
              You will not have to pay a fee to access your personal data (or to
              exercise any of the other rights). However, we may charge a
              reasonable fee if your request is clearly unfounded, repetitive or
              excessive. Alternatively, we may refuse to comply with your
              request in these circumstances. COG-OT does not store or hold
              patient data, this data remains on the device so any subject
              access right made by a patient will need to be directed to the
              organisation to which the patient is attached;
            </li>
            <li>
              to request us to amend your personal data so that it is correct
              and complete;{" "}
            </li>
            or
            <li>to request us to delete your personal data.</li>
          </ul>
          <p>
            We may need to request specific information from you to help us
            confirm your identity and ensure you have the right to access your
            personal data (or to exercise any of your other rights). This is a
            security measure to make sure your information is not disclosed to
            any person who has no right to receive it. We may also contact you
            by email to ask you for further information in relation to your
            request to speed up our response.
          </p>
          <p>
            We try to respond to all legitimate requests within one month.
            Occasionally it may take us longer than a month if your request is
            particularly complex or you have made several requests. In this
            case, we will notify you and keep you updated. You also have the
            right to make a complaint to the Information Commissioner’s Office –
            the supervisory authority that handles data protection law in the
            UK. You can contact them at:
          </p>
          <p>
            Their website:{" "}
            <a href="https://ico.org.uk/make-a-complaint/">
              https://ico.org.uk/make-a-complaint/
            </a>
          </p>
          <p>
            Information Commissioner’s Office
            <br />
            Wycliffe House
            <br />
            Water Lane
            <br />
            Wilmslow
            <br />
            Cheshire SK9 5AFE
            <br />
            casework@ico.org.uk
            <br />
            Telephone: 0303 123 1113
          </p>
          <h2>THIRD PARTY WEBSITES</h2>
          <p>
            Our site may, from time to time, contain links to and from the
            websites of our partner networks and affiliates. If you follow a
            link to any of these websites, please note that these websites have
            their own privacy policies, we do not accept any responsibility or
            liability for the policies. Please check these policies before you
            submit any personal data to these websites.
          </p>
          <h2>CONTACT US</h2>
          <p>
            If you have any questions about how we use your personal data, or
            want to exercise your rights over your information, you can use our
            contact form email us on{" "}
            <a href="mailto:cog.ot.project@gmail.com">
              cog.ot.project@gmail.com
            </a>
          </p>
          <h2>POLICY INFORMATION</h2>
          <p>
            This policy was last updated on the 7st October 2020. We may update
            it at any time.
          </p>
        </article>
      </main>
    </>
  );
};

export default Privacy;
