import ReactGA from "react-ga";

const trackEvent = (category, action, label) => {
  ReactGA.event({
    category,
    action,
    label,
  });
};

export default trackEvent;
