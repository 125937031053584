import React from "react";
import BackLink from "../components/BackLink";

import { theme } from "../styles/theme";
import { css } from "emotion";

const Cookies = () => {
  return (
    <>
      <header className={css(theme.components.header)}>
        <h1>COG-OT Project Cookie Policy</h1>
      </header>
      <main>
        <BackLink to="/">Back to home</BackLink>
        <article className="theme">
          <p>
            When you visit this website, we may send one or more cookies — a
            small text filecontaining a string of alphanumeric characters — to
            your computer that uniquelyidentifies your browser and lets COG-OT
            enhance your navigation through the site. A cookie may also convey
            information to us about how you use the site (e.g., the pages you
            view, the links you click and other actions you take on the site),
            and allow us or our business partners to track your usage of the
            site over time. A persistent cookie remains on your hard drive after
            you close your browser. Persistent cookies may be used by your
            browser on subsequent visits tothe site. Persistent cookies can be
            removed by following your web browser’s directions. A session cookie
            is temporary and disappears after you close your browser. You can
            reset your web browser to refuse all cookies or to indicate when a
            cookie is being sent. However, some features of the site may not
            function properly if the ability to accept cookies is disabled.
          </p>
          <dl>
            <dt>Cookie Names:</dt>
            <dd>Session, connect.sid</dd>
            <dt>Description:</dt>
            <dd>
              This cookie is necessary for the site to work by maintaining your
              session. It contains no personal information.
            </dd>
          </dl>
          <dl>
            <dt>Cookie Names:</dt>
            <dd>__utma, __utmb, __utmc, __utmz, __utmt, NID, GAPS, GALX</dd>
            <dt>Description:</dt>
            <dd>
              These are deployed by our Google Analytics only if you accept our
              cookies. They collect information such as the pages you visit, the
              length of time you spend on the site and what browser you are
              using. This information helps us improve and optimise the site in
              the future.
            </dd>
          </dl>
        </article>
      </main>
    </>
  );
};

export default Cookies;
