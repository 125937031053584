import React, { useState, useContext } from "react";
import { Redirect } from "react-router-dom";

import TopicContext from "../context/TopicContext";
import Header from "../components/Header";
import Error from "../components/Error";
import trackEvent from "../utils/trackEvent";
import { theme } from "../styles/theme";
import { css } from "emotion";

const btnList = css({
  display: "grid",
  gridGap: theme.gap,
  gridTemplateColumns: "1fr 1fr",
  marginBottom: theme.gapDouble,
});
const btn = css({
  label: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    padding: theme.gap,
    background: theme.colours.white,
    borderRadius: theme.borderRadius,
    border: `2px solid ${theme.colours.lightGrey}`,
    height: "100%",
    fontWeight: "bold",
    transition: "all 0.2s ease",
  },

  input: {
    appearance: "none",
    opacity: 0,
    position: "absolute",
    height: "1px",

    "&:checked + label": {
      borderColor: theme.colours.purple,
      boxShadow: theme.shadow,
    },
  },
});

const Topics = ({ location }) => {
  const {
    topics,
    selectedTopics,
    updateGuideContent,
    updateSelectedTopics,
    error,
  } = useContext(TopicContext);

  const [navigate, setNavigate] = useState(false);

  const handleChoice = (id) => {
    updateSelectedTopics(id);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    selectedTopics.forEach((topicId) => {
      const topicName = topics.find((t) => t.sys.id === topicId).fields.name;
      trackEvent("Topic", "Topic Selected", topicName || topicId);
    });
    updateGuideContent();
    setNavigate(true);
  };

  return (
    <>
      {navigate && (
        <Redirect
          push
          to={{
            pathname: "/subtopics",
          }}
        />
      )}

      <Header title="Select some topics to focus on:" />

      <main>
        {error && <Error error={error} />}

        {topics.length && (
          <form onSubmit={handleFormSubmit}>
            <ul className={btnList}>
              {topics.map((t) => {
                return (
                  <li key={t.sys.id} className={btn}>
                    <input
                      type="checkbox"
                      id={t.sys.id}
                      checked={selectedTopics.includes(t.sys.id)}
                      onChange={() => handleChoice(t.sys.id)}
                    />
                    <label htmlFor={t.sys.id}>
                      {t.fields.listImage && (
                        <img
                          src={t.fields.listImage.fields.file.url}
                          width={
                            t.fields.listImage.fields.file.details.image.width
                          }
                          height={
                            t.fields.listImage.fields.file.details.image.height
                          }
                          alt={`Icon for ${t.fields.name}`}
                          className={css({
                            marginBottom: theme.gap,
                            maxWidth: "55px",
                            maxHeight: "55px",
                          })}
                        />
                      )}
                      <p>{t.fields.name}</p>
                    </label>
                  </li>
                );
              })}
            </ul>
            <input
              type="submit"
              className={theme.components.button}
              disabled={!selectedTopics.length}
              value="Done"
            />
          </form>
        )}

        {!topics.length && (
          <p>Couldn't find any topics. Check your internet connection.</p>
        )}
      </main>
    </>
  );
};

export default Topics;
