import React from "react";
import { theme } from "../styles/theme";
import { css } from "emotion";

const cardStyle = css({
  marginBottom: theme.gapDouble,
  borderRadius: theme.borderRadius,
  border: `2px solid ${theme.colours.purpleTint}`,
  boxShadow: theme.shadow,

  header: {
    display: "flex",
    alignItems: "center",
    background: theme.colours.purpleTint,
    color: theme.colours.white,
    padding: `${theme.gapHalf} ${theme.gap}`,
    fontSize: theme.fzH2,

    " & > img": {
      marginTop: theme.gapHalf,
      marginBottom: theme.gapHalf,
      marginRight: theme.gapHalf,
      maxWidth: "30px",
      maxHeight: "30px",
    },

    h2: {
      marginRight: "auto",
    },
  },
});

const Card = ({ title, image, headerRight = null, children }) => {
  return (
    <article className={cardStyle}>
      <header>
        {image && <img src={image.fields.file.url} alt={`Icon for ${title}`} />}
        <h2>{title}</h2>
        {headerRight}
      </header>
      {children}
    </article>
  );
};

export default Card;
