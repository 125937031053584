import React from "react";
import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";
import PdfDocument from "../containers/PdfDocument";

const generatePdfDocument = async (content) => {
  const blob = await pdf(
    <PdfDocument title="COG-OT Guide" content={content} />
  ).toBlob();
  saveAs(blob, "COG-OT-Guide.pdf");
};

export default generatePdfDocument;
