import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Header from "../components/Header";
import Loading from "../components/Loading";
import Error from "../components/Error";
import FaqItem from "../components/FaqItem";

import getContentfulEnvironment from "../utils/getContentfulEnvironment";

import { createClient } from "contentful";

import { theme } from "../styles/theme";
import { css } from "emotion";

const faqList = css({
  marginBottom: theme.gapDouble,
});

const FAQs = () => {
  const [FAQs, setFAQs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const accessToken = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN;
  const space = process.env.REACT_APP_CONTENTFUL_SPACE;

  // Create contentful client
  const client = createClient({
    space,
    accessToken,
    environment: getContentfulEnvironment(),
  });

  // Fetch topics data from contentful and setState
  useEffect(() => {
    async function fetchData() {
      try {
        const faqsData = await client.getEntries({
          content_type: "faq",
        });
        setFAQs(faqsData.items);
        setLoading(false);
      } catch (error) {
        const { message } = error;
        setError(message);
      }
    }

    fetchData();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Header title="About COG-OT" />

      <main>
        {error && <Error error={error} />}
        {!error && loading && <Loading message="Loading..." />}

        {!error && FAQs && (
          <ul className={faqList}>
            {FAQs.map((faq) => {
              return (
                <li key={faq.sys.id}>
                  <FaqItem
                    question={faq.fields.question}
                    answer={faq.fields.answer}
                    id={faq.sys.id}
                  />
                </li>
              );
            })}
          </ul>
        )}

        <Link to="/" className={css(theme.components.button)}>
          Home
        </Link>
      </main>
    </>
  );
};

export default FAQs;
