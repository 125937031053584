import React, { useState, useContext } from "react";
import { Redirect } from "react-router-dom";

import TopicContext from "../context/TopicContext";

import Header from "../components/Header";
import Card from "../components/Card";
import Error from "../components/Error";
import BackLink from "../components/BackLink";

import trackEvent from "../utils/trackEvent";

import { theme } from "../styles/theme";
import { css, cx } from "emotion";

const topicListStyle = css({
  borderRadius: theme.borderRadius,
});
const subtopicListStyle = css({
  padding: theme.gap,
});
const subtopicOptionStyle = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.gap,
  background: theme.colours.white,
  marginBottom: theme.gapHalf,
  border: `2px solid ${theme.colours.lightGrey}`,
});
const checkboxStyle = css({
  appearance: "none",
  border: `4px solid ${theme.colours.lightGrey}`,
  width: "25px",
  height: "25px",
  borderRadius: "50%",
  background: "none",
  flexShrink: "0",

  "&:focus": {
    outline: "none",
  },
  "&:checked": {
    background: theme.colours.purple,
  },
});
const checkedStyle = css({
  borderColor: theme.colours.purple,
  boxShadow: theme.shadow,
});

const Subtopics = () => {
  const {
    topics,
    selectedTopics,
    selectedSubtopics,
    updateSelectedSubtopics,
    updateGuideContent,
    error,
  } = useContext(TopicContext);

  const chosenTopics = topics.filter((topic) =>
    selectedTopics.includes(topic.sys.id)
  );

  const [navigate, setNavigate] = useState(false);

  const handleChoice = (id) => {
    updateSelectedSubtopics(id);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    selectedSubtopics.forEach((subtopicId) => {
      let subtopicName = null;
      chosenTopics.forEach((t) =>
        t.fields.subtopic.forEach((subt) => {
          if (subt.sys.id === subtopicId) subtopicName = subt.fields.name;
        })
      );
      trackEvent("Subtopic", "Subtopic Selected", subtopicName || subtopicId);
    });
    updateGuideContent();
    setNavigate(true);
  };

  return (
    <>
      {!topics.length && <Redirect to="topics" />}

      {navigate && (
        <Redirect
          push
          to={{
            pathname: "/guide",
          }}
        />
      )}

      {error && <Error error={error} />}

      <Header title="Select some key topics to focus on:" />

      <main>
        <BackLink to="topics">Change topics</BackLink>

        <form onSubmit={handleFormSubmit}>
          <ul className={topicListStyle}>
            {chosenTopics.map((t) => {
              return (
                <li key={t.sys.id}>
                  <Card title={t.fields.name} image={t.fields.iconImage}>
                    <ul className={subtopicListStyle}>
                      {t.fields.subtopic.map((s) => {
                        return (
                          <li key={s.sys.id}>
                            <label
                              htmlFor={s.sys.id}
                              className={cx(subtopicOptionStyle, {
                                [checkedStyle]: selectedSubtopics.includes(
                                  s.sys.id
                                ),
                              })}
                            >
                              <p>{s.fields.name}</p>
                              <input
                                type="checkbox"
                                id={s.sys.id}
                                checked={selectedSubtopics.includes(s.sys.id)}
                                onChange={() => handleChoice(s.sys.id)}
                                className={checkboxStyle}
                              />
                            </label>
                          </li>
                        );
                      })}
                    </ul>
                  </Card>
                </li>
              );
            })}
          </ul>

          <input
            type="submit"
            className={theme.components.button}
            disabled={!selectedSubtopics.length}
            value="View Checklist"
          />
        </form>
      </main>
    </>
  );
};

export default Subtopics;
