import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { theme } from "../styles/theme";
import { css } from "emotion";

const cookieBannerStyle = css({
  position: "fixed",
  bottom: 0,
  left: 0,
  width: "100%",
  padding: theme.gap,
  color: theme.colours.white,
  background: theme.colours.midGrey,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  a: {
    color: "inherit",
  },

  button: {
    marginLeft: theme.gap,
    background: "none",
    color: theme.colours.white,
    border: "1px solid white",
    padding: theme.gapHalf,
  },
});

const CookieNotice = () => {
  const [cookiesAccepted, setCookiesAccepted] = useState(false);

  useEffect(() => {
    // Check if cookiesAccepted === true in localStorage and setState accordingly
    if (
      window.localStorage.getItem("cookiesAccepted") &&
      window.localStorage.getItem("cookiesAccepted") === "true"
    )
      setCookiesAccepted(true);
    // eslint-disable-next-line
  }, []);

  const handleCloseClick = () => {
    // Set localStorage
    window.localStorage.setItem("cookiesAccepted", "true");
    setCookiesAccepted(true);
  };

  if (cookiesAccepted) {
    return null;
  } else {
    return (
      <div role="alert" className={cookieBannerStyle}>
        <p>
          By using COG-OT, you acknowledge that you have read and understood our{" "}
          <Link to="privacy">Privacy Notice</Link>,{" "}
          <Link to="cookies">Cookie Policy</Link> and our{" "}
          <Link to="terms">Terms of Service</Link>
        </p>
        <button onClick={handleCloseClick}>Close</button>
      </div>
    );
  }
};

export default CookieNotice;
