import React, { useEffect } from "react";

import { Switch, Route, useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import PWAPrompt from "react-ios-pwa-prompt";

import { TopicProvider } from "./context/TopicContext";

import ScrollToTop from "./components/ScrollToTop";
import CookieNotice from "./components/CookieNotice";

import Home from "./containers/Home";
import Privacy from "./containers/Privacy";
import Cookies from "./containers/Cookies";
import Terms from "./containers/Terms";
import About from "./containers/About";
import Topics from "./containers/Topics";
import Subtopics from "./containers/Subtopics";
import Guide from "./containers/Guide";

const usePageViews = () => {
  let location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, [location]);
};

const App = () => {
  // Init analytics
  useEffect(() => {
    ReactGA.initialize(
      process.env.REACT_APP_GOOGLE_ANALYTICS_ID ?? "UA-178515846-2",
      { debug: false }
    );
  }, []);

  // Track pageviews
  usePageViews();

  // Set timestamp of when app was first used. Originally was used to show a survey after a while of use. Keeping in case of future use
  useEffect(() => {
    const timestampFirstUsed = window.localStorage.getItem(
      "cot_timestampFirstUsed"
    );
    if (!timestampFirstUsed) {
      // No first use date recorded, so set it now
      window.localStorage.setItem("cot_timestampFirstUsed", Date.now());
    }

    // eslint-disable-next-line
  }, []);

  return (
    <TopicProvider>
      <ScrollToTop />
      <PWAPrompt />
      <Switch>
        <Route path="/guide" component={Guide} />
        <Route path="/subtopics" component={Subtopics} />
        <Route path="/topics" component={Topics} />
        <Route path="/about" component={About} />
        <Route path="/cookies" component={Cookies} />
        <Route path="/terms" component={Terms} />
        <Route path="/privacy" component={Privacy} />
        <Route path="/" component={Home} />
      </Switch>
      <CookieNotice />
    </TopicProvider>
  );
};

export default App;
