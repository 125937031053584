import React from "react";
import { Link } from "react-router-dom";

import { theme } from "../styles/theme";
import { css, cx } from "emotion";

import logo from "../assets/COG-OT-plus-logo.svg";
import arrowIcon from "../assets/icon-arrow.svg";
import cogOTIcon from "../assets/icon-cog-ot.svg";

const btnStyle = css({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  background: theme.colours.white,
  padding: theme.gap,
  paddingTop: theme.gapDouble,
  paddingBottom: theme.gapDouble,
  textDecoration: "none",
  fontSize: theme.fzH1,
  boxShadow: theme.shadow,
  borderRadius: theme.borderRadius,
  marginBottom: theme.gap,
  width: "600px",
  maxWidth: "100%",
});
const aboutBtnStyle = css({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  background: theme.colours.white,
  padding: theme.gap,
  paddingTop: theme.gapDouble,
  paddingBottom: theme.gapDouble,
  textDecoration: "none",
  fontSize: theme.fzH1,
  boxShadow: theme.shadow,
  borderRadius: theme.borderRadius,
  marginBottom: theme.gap,
  width: "600px",
  maxWidth: "100%",
  position: "relative",
});

const Home = ({ showSurveyLink, showWorkshopInfo }) => {
  return (
    <>
      <header
        className={css([
          theme.components.header,
          {
            fontSize: "2rem",
            background: theme.colours.purple,
          },
        ])}
      >
        <img src={logo} alt="COG-OT+ Supporting Digital Goals" width="320" />
      </header>
      <main
        className={css({
          background: theme.gradientVertical,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: "none!important",
          paddingTop: theme.gap + "!important",
        })}
      >
        <h1
          className={css({
            fontSize: theme.fzH2,
            color: theme.colours.white,
            marginBottom: theme.gap,
            textAlign: "center",
            width: "450px",
            maxWidth: "100%",
          })}
        >
          Clinical onboarding guide for Occupational Therapists using digital
          interventions
        </h1>

        <Link to="/topics" className={btnStyle}>
          <img
            src={arrowIcon}
            alt="Arrow Icon"
            width="200"
            height="200"
            className={css({
              marginBottom: theme.gap,
            })}
          />
          <h2 className={theme.components.button}>START A NEW GUIDE</h2>
        </Link>
        <Link to="/about" className={aboutBtnStyle}>
          <img src={cogOTIcon} alt="Cog OT Icon" width="180" height="180" />
          <h2
            className={cx(
              theme.components.button,
              css({
                backgroundColor: theme.colours.purpleLight,
                position: "absolute",
                width: `calc(100% - ${theme.gapDouble})`,
                left: theme.gap,
                top: "50%",
                transform: "translateY(-50%)",
                "&:hover": {
                  backgroundColor: theme.colours.purple,
                },
              })
            )}
          >
            About COG-OT
          </h2>
        </Link>
      </main>
    </>
  );
};

export default Home;
