import React from "react";
import { theme } from "../styles/theme";
import { css, cx } from "emotion";

const input = css({
  appearance: "none",
  opacity: "0",
  position: "absolute",
  transform: "scale(0)",
});
const label = css({
  textTransform: "uppercase",
  border: `2px solid ${theme.colours.white}`,
  borderRadius: theme.borderRadius,
  padding: theme.gap,
  minWidth: "70px",
  marginLeft: theme.gapHalf,
  textAlign: "center",
  transition: "all 0.2s ease",
});
const checkedStyle = css({
  background: theme.colours.white,
  color: theme.colours.purpleTint,
});

const FeedbackButton = ({ id, value, onChange, submitted }) => {
  return (
    <>
      <input
        type="radio"
        id={`feedback_${id}_${value}`}
        checked={submitted}
        onChange={onChange}
        className={input}
      />
      <label
        htmlFor={`feedback_${id}_${value}`}
        className={cx(label, {
          [checkedStyle]: submitted,
        })}
      >
        {value}
      </label>
    </>
  );
};

export default FeedbackButton;
