import React from "react";
import { Link } from "react-router-dom";
import { css } from "emotion";
import { theme } from "../styles/theme";

const backLinkStyle = css({
  display: "inline-flex",
  alignItems: "center",
  textDecoration: "none",
  color: theme.colours.purple,
  paddingBottom: theme.gap,
  marginBottom: theme.gap,
});

const BackLink = ({ to, children }) => {
  return (
    <Link to={to} className={backLinkStyle}>
      <svg
        height="24"
        width="24"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 0h24v24H0z" stroke="none" />
        <line x1="5" x2="19" y1="12" y2="12" />
        <line x1="5" x2="11" y1="12" y2="18" />
        <line x1="5" x2="11" y1="12" y2="6" />
      </svg>
      {children}
    </Link>
  );
};

export default BackLink;
