import React from "react";
import BackLink from "../components/BackLink";

import { theme } from "../styles/theme";
import { css } from "emotion";

const Terms = () => {
  return (
    <>
      <header className={css(theme.components.header)}>
        <h1>COG-OT Project Terms and Conditions</h1>
      </header>
      <main>
        <BackLink to="/">Back to home</BackLink>
        <article className="theme">
          <p>
            These terms and conditions are the contract between you and COG-OT
            (“us”, “we”, etc). By visiting or using Our Website, you agree to be
            bound by them. They protect your rights as well as ours.
          </p>
          <p>
            You are: Anyone who uses Our Website, COG-OT Clinical Onboarding
            Guide for Occupational Therapists.
          </p>
          <p>
            Please read this agreement carefully. If you do not agree with it,
            you should leave Our Website immediately.
          </p>
          <p>
            <strong>These are the agreed terms</strong>
          </p>
          <h2>1. Definitions</h2>
          <dl>
            <dt>“Content”</dt>
            <dd>
              means the textual, visual or aural content that is encountered as
              part of your experience on Our Website. It may include, among
              other things: text, images, sounds, videos and animations.
            </dd>
            <dt>"Intellectual Property"</dt>
            <dd>
              means intellectual property owned by us, of every sort, whether or
              not registered or registrable in any country, including
              intellectual property of all kinds coming into existence after
              today; and including, among others, patents, trade marks,
              unregistered marks, designs, copyrights, software, domain names,
              discoveries, creations and inventions, together with all rights
              which are derived from those rights.
            </dd>
            <dt>“Our Website”</dt>
            <dd>
              means any website or service designed for electronic access by
              mobile or fixed devices which is owned or operated by us.
            </dd>
            <dt>“Services”</dt>
            <dd>means the service provided from Our Website.</dd>
          </dl>
          <h2>2. Children on Our Website</h2>
          <div className="pl-4">
            <p>
              2.1. Whatever the age of consent in your country, we are anxious
              that they should be protected from unsuitable Content. To protect
              your children, you should know our policy, which is as follows:
            </p>
            <p>
              2.2. In the children categories, our volunteers have checked both
              the entries, and, where relevant, the links.
            </p>
            <p>
              2.3. We do not knowingly collect personal information from any
              person under the age of 16 years.
            </p>
            <p>
              2.4. Any person of any age may freely access any page of Our
              Website. We do not check identities nor moderate Content.
            </p>
            <p>
              2.5. It is you, not we, who provide access to Our Website for the
              children in your care. It is for you to check that the Content
              your children might see is suitable for them.
            </p>
            <p>
              2.6. Where links are concerned, you may like to check the privacy
              policies of those sites where your children might visit frequently
              to see how they collect and use information.
            </p>
            <p>2.7. Filter software may also be useful to you.</p>
            <p>
              2.8. You acknowledge that we are not responsible for Content that
              anyone has placed on Our Website for the content of site
              accessible by a link from Our Website.
            </p>
            <p>
              2.9. You now agree to waive any claim you may otherwise have
              against us on account of age-related suitability of Content and to
              indemnify us against any claim made by any person on behalf of a
              child in your care.
            </p>
          </div>
          <h2>3. Intellectual Property</h2>
          <div className="pl-4">
            <p>You agree that at all times you will:</p>
            <p>
              3.1. not do anything which does or might reduce the value of our
              Intellectual Property or challenge our ownership of it.
            </p>
            <p>
              3.2. notify us of any suspected infringement of the Intellectual
              Property;
            </p>
            <p>
              3.3. so far as concerns our work provided or made accessible by us
              to you, you will not:
            </p>
            <div className="pl-4">
              <p>3.3.1 copy, or make any change to any part of its code;</p>

              <p>3.3.2 use it in any way not anticipated by this agreement;</p>

              <p>
                3.3.3 give access to it to any other person than you, the
                licensee in this agreement;
              </p>
              <p>
                3.3.4 in any way provide any information about it to any other
                person or generally.
              </p>
            </div>
            <p>
              3.4. not use the Intellectual Property except directly as intended
              by this agreement or in our interest.
            </p>
          </div>
          <h2>4. Disclaimers and limitation of liability</h2>
          <div className="pl-4">
            <p>
              4.1. The law differs from one country to another. This paragraph
              applies so far as the applicable law allows.
            </p>
            <p>
              4.2. All implied conditions, warranties and terms are excluded
              from this agreement. If in any jurisdiction an implied condition,
              warrant or term cannot be excluded, then this sub paragraph shall
              be deemed to be reduced in effect, only to the extent necessary to
              release that specific condition, warranty or term.
            </p>
            <p>
              4.3. You are advised that Content may include technical
              inaccuracies or typographical errors. This is inevitable in any
              large website. We would be grateful if you bring to our immediate
              attention, any that you find.
            </p>
            <p>
              4.4. Our Website contains links to other internet websites. We
              have neither power nor control over any such website. You
              acknowledge and agree that we shall not be liable in any way for
              the Content of any such linked website, nor for any loss or damage
              arising from your use of any such website or from your buying
              services or goods via such a website.
            </p>
            <p>
              4.5. The COG-OT Website is provided “as is”. We make no
              representation or warranty that Our Website will be:
            </p>
            <div className="pl-4">
              <p>4.5.1 useful to you;</p>
              <p>4.5.2 of satisfactory quality;</p>
              <p>4.5.3 fit for a particular purpose;</p>
              <p>
                4.5.4 available or accessible, without interruption, or without
                error.
              </p>
            </div>
            <p>
              4.6. We claim no expert knowledge in any subject outside the
              training of the team as Occupational Therapists regulated by the
              HCPC who contributed content within their scope of expertise. We
              disclaim any obligation or liability to you arising directly or
              indirectly from information you take from Our Website.
            </p>
            <p>4.7. We accept no responsibility for third party links</p>
            <p>
              4.8. We shall not be liable to you for any loss or expense which
              is:
            </p>
            <div className="pl-4">
              <p>4.8.1 indirect or consequential loss; or</p>{" "}
              <p>
                4.8.2 economic loss or other loss of turnover, profits, business
                or goodwill even if such loss was reasonably foreseeable or we
                knew you might incur it.
              </p>
            </div>
            <p>
              4.9. This paragraph (and any other paragraph which excludes or
              restricts our liability) applies to our volunteers,
              subcontractors, agents and affiliated companies (who may enforce
              this clause under the Contracts (Rights of Third Parties) Act 1999
              / Contracts (Rights of Third Parties) (Scotland) Act 2017 as well
              as to ourselves.
            </p>
          </div>
          <h2>5. Miscellaneous matters</h2>
          <div className="pl-4">
            <p>
              5.1. Our privacy policy is strong and precise. It complies fully
              with the Data Protection Act 2018 which is at [link to privacy
              policy].
            </p>
            <p>
              5.2. If any term or provision of this agreement is at any time
              held by any jurisdiction to be void, invalid or unenforceable,
              then it shall be treated as changed or reduced, only to the extent
              minimally necessary to bring it within the laws of that
              jurisdiction and to prevent it from being void and it shall be
              binding in that changed or reduced form. Subject to that, each
              provision shall be interpreted as severable and shall not in any
              way affect any other of these terms.
            </p>
            <p>
              5.3. No failure or delay by any party to exercise any right, power
              or remedy will operate as a waiver of it nor indicate any
              intention to reduce that or any other right in the future.
            </p>
            <p>
              5.4. Any communication to be served on either party by the other
              shall be delivered by hand or sent by first class post or by
              e-mail. It shall be deemed to have been delivered: if delivered by
              hand: on the day of delivery; if sent by post to the correct
              address: within 72 hours of posting; If sent by e-mail to the
              address from which the receiving party has last sent e-mail:
              within 24 hours if no notice of non-receipt has been received by
              the sender.
            </p>
            <p>
              5.5. The validity, construction and performance of this agreement
              shall be governed by the laws of [England and Wales / Scotland /
              Northern Ireland] and you agree that any dispute arising from it
              shall be litigated only in that country.
            </p>
          </div>
        </article>
      </main>
    </>
  );
};

export default Terms;
