import React from "react";
import { theme } from "../styles/theme";
import { css } from "emotion";

const Error = ({ error }) => {
  return (
    <p
      className={css({
        marginBottom: theme.gap,
        color: theme.colours.error,
      })}
    >
      {error}
    </p>
  );
};

export default Error;
